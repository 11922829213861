<template >
    <div id="video-c">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
             
                <HeaderHome :name="user.fullname" /> 
            </div>

        <div class="video-cont">
            <div class="video-sec-cont">
                <VideosSlide />
                <div class="videos-cont">
                    <UltimosVideos2 />
                </div>
            </div>
        </div>

         <div id="footerSection">
             <Footer />
         </div>
    </div>
</template>

<script>
import Footer from '../components/footer'
import HeaderHome from '../components/HeaderHome';
import VideosSlide from '../components/VideoSlide';
import UltimosVideos2 from '../components/UltimosVideos2';
import {mapState,mapActions,mapMutations} from 'vuex';

export default {
    components: {
        HeaderHome,
        VideosSlide,
        UltimosVideos2,
        Footer
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
    },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Videos - Cimarrones de Sonora';
            }
        },
    }
}
</script>