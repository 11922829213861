<template>
  <div class="last-v-dos">
    <div class="titulo-u-noticias">
      <h4>ULTIMOS <span>VIDEOS</span></h4>
     <!-- <Sponsor3 :secSponsor3="'VIDEOS'" />-->
    </div>
    <div class="last-cont">
      <VideoModal v-show="isModalVisible3" @close="closeModal3">
        <template v-slot:header> {{ modalData3.title }} </template>

        <template v-slot:body> <div v-html="modalData3.url"></div> </template>
      </VideoModal>
      <div class="l-cont" v-for="(vid,key) in videodata3" :key="key" @click="showModal3(vid)">
        <div class="l-div">
            <div :style="{ backgroundImage: 'url(' + url+'/get-video-image/'+vid.image + ')'}" width="377" height="350" alt="e" class="l-m-content"/>
            <img
              src="../assets/red-rec.png"
              width="118"
              height="12"
              alt="rr"
              class="red-rec"
            />
             <img src="../assets/play1.png" alt="play" class="play-video-icon">
        </div>
        <div class="titulo-vim">
          <h6 class="date">{{ dataSortDue(vid.datevideo) }}</h6>
          <a>
            <h4 class="titulo">{{ vid.title }}</h4>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VueCoreVideoPlayer from "vue-core-video-player";
import Sponsor3 from "../components/widgets/SponsorSecW3";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { mapActions } from "vuex";
import VideoModal from "../components/VideoModal.vue";

export default {
  components: {
    VueCoreVideoPlayer,
    Sponsor3,
    VideoModal,
  },
  data() {
    return {
      lastvideo: [
        {
          link: "#",
          img: require("../assets/vim1.png"),
          fecha: "Hace 2 días",
          titulo: "Vallejo va por más goles ante Venados",
          prevtext:
            "Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,",
        },
        {
          link: "#",
          img: require("../assets/vim2.png"),
          fecha: "Hace 2 días",
          titulo: "Vallejo va por más goles ante Venados",
          prevtext:
            "Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,",
        },
        {
          link: "#",
          img: require("../assets/vim3.png"),
          fecha: "Hace 2 días",
          titulo: "Vallejo va por más goles ante Venados",
          prevtext:
            "Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,",
        },
        {
          link: "#",
          img: require("../assets/vim4.png"),
          fecha: "Hace 2 días",
          titulo: "Vallejo va por más goles ante Venados",
          prevtext:
            "Con un solitario gol del “Guamerucito” Brian García los Cimarrones sellaron su sexta victoria en casa del torneo y llegaron a 27 unidades,",
        },
      ],
      isModalVisible3: false,
      modalData3: null,
    };
  },
  created() {
    this.findAll3();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    videodata3() {
      let videoda = this.$store.getters["video/data2"];
      let elekipo = videoda.sort(
        (a, b) => {return new Date(b.datevideo) - new Date(a.datevideo);}
      );
      return elekipo;    },
  },
  methods: {
    ...mapActions("video", ["findAll"]),

    showModal3(data) {
      if(data.urltitle =='' || !data.urltitle){
         this.isModalVisible3 = true;
         this.modalData3 = data;
      }else{
         const path ="/video/"+data.urltitle
         window.location.href = path;  
      }
     
    },
    closeModal3() {
      this.isModalVisible3 = false;
      this.modalData3 = '';
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    videoId: function (url) {
      let match = url.match(/v=([0-9a-z_-]{1,20})/i);
      return match ? match["1"] : false;
    },
    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

 get_youtube_thumbnail: function(url, quality){
    if(url){
        var video_id, thumbnail, result;
        if(result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))
        {
            video_id = result.pop();
        }
        else if(result = url.match(/youtu.be\/(.{11})/))
        {
            video_id = result.pop();
        }

        if(video_id){
            if(typeof quality == "undefined"){
                quality = 'high';
            }
        
            var quality_key = 'maxresdefault'; // Max quality
            if(quality == 'low'){
                quality_key = 'sddefault';
            }else if(quality == 'medium'){
                quality_key = 'mqdefault';
            } else if (quality == 'high') {
                quality_key = 'hqdefault';
            }

            var thumbnail = "http://img.youtube.com/vi/"+video_id+"/"+quality_key+".jpg";
            return thumbnail;
        }
    }
    return false;
},






    findAll3: async function () {
      let result = await this.$store.dispatch("video/findAll3", {
        option: "videos",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>


<style scoped>
#video-c .play-video-icon {
    position: absolute;
    height: 19vw;
    width: 19vw;
   margin-left: 0;
    padding: 7vw;
    z-index: 99;
    display: flex;
    transition: .25s;
    /* align-items: center; */
    /* justify-content: center; */
    /* align-content: center; */
}/*#video-c .play-video-icon:hover {
    padding: 6.5vw;
}
@media (max-width: 768px) {
.last-v-dos img.play-video-icon {
    width: 8vw;
    margin-left: -14vw;
    margin-top: 5vw;
}}*/
.l-m-content {
    background-size: 140%!important;
    background-position-x: center;
    transition: .5s;
}
.l-cont:hover .l-m-content {
    background-size: 180%!important;
}
</style>