


<template>
  <div class="video-slides">
    <div class="header-video-cont">
      <div>
        <div class="video-cont">
         <!--<div class="titulo-video-cont">
            <div @click="showModal">
              <h6 class="data-video-sl">
                HACE {{ newsdateago(this.videodata1.datevideo) }}
              </h6>
              <h1 class="titulo-vid-sl">
                {{ this.videodata1.title }}
              </h1>
            </div>
          </div>-->
          <img class="video-header-sl pc" :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image" />
          <img class="video-header-sl mo" :src="urlpath.url() + '/get-cabeceras-image/' + backgr.image_mv" />
        </div>
      </div>
    </div>

    <VideoModal v-show="isModalVisible" @close="closeModal">
      <template v-slot:header>  {{videodata1.title}} </template>

      <template v-slot:body> <div v-html="videodata1.url"></div> </template>
    </VideoModal>

    <div class="slide-dest-cont">
    <VideoModal v-show="isModalVisible2" @close="closeModal2">
      <template v-slot:header>  {{modalData.title}} </template>

      <template v-slot:body> <div v-html="modalData.url"></div> </template>
    </VideoModal>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "vueperslides/dist/vueperslides.css";
import VideoModal from "../components/VideoModal.vue";
let urlpath = require('../global/url');

export default {
  components: { VideoModal },
  data() {
    return {
      isModalVisible: false,
      isModalVisible2: false,
      modalData: null,
      urlpath,
      backgr: [],
    };
  },
  async created() {
    this.findAll();
    this.findById1();
      let payload2 = {
        id: 'Videos',
        option: "cabeceras/section",
      };
      this.backgr = await this.getCabecerasById(payload2);
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    videodata() {
      let videoda = this.$store.getters["video/data"];
      return videoda.sort((a, b) => new Date(b.datevideo).getTime() > new Date(a.datevideo).getTime()).reverse();
    },
    videodata1() {
      return this.$store.getters["video/data1"];
    },
    urlyt() {
    return String(this.videodata1.url);
    },
    titleyt() {
    return String(this.videodata1.title);
    },
  },
  methods: {
    ...mapActions("video", ["findAll"]),
    ...mapActions("video", ["findById1"]),
    ...mapActions("cabeceras", ["getCabecerasById"]), 

    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    showModal2(data) {
      this.isModalVisible2 = true;
      this.modalData = data
    },
    closeModal2() {
      this.isModalVisible2 = false;
      this.modalData = null
    },

    videoId: function (url) {
      let match = url.match(/v=([0-9a-z_-]{1,20})/i);
      return match ? match["1"] : false;
    },
    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

    findById1: async function () {
      let idm = 2;
      let result = await this.$store.dispatch("video/findById1", {
        option: "videos",
        id: idm,
      });
      //this.info = result;
      return result;
    },

    findAll: async function () {
      let result = await this.$store.dispatch("video/findAll", {
        option: "videos",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },
  },
};
</script>

<style scoped>
.video-cont .slide-dest-cont{display: none;}
.video-header-sl.pc{
  display: block;
}
.video-header-sl.mo{
  display: none;
}
@media(max-width: 768px){
.video-header-sl.pc{
  display: none;
}
.video-header-sl.mo{
  display: block;
}
}
</style>